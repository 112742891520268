import React from "react"
import { FaLinkedinIn } from "react-icons/fa"

let data = [
  {
    images: "03",
    title: "Prince Dubey",
    designation: "Associate Director - Talent Acquisition & Operations",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "http://www.linkedin.com/in/prince-dubey-1123a169",
      },
    ],
  },
  {
    images: "02",
    title: "Vinod Kumar Singh",
    designation: "  Associate Director Administration",
    socialNetwork: [],
  },
  {
    images: "01",
    title: "Upasana Bhan",
    designation: "Founder",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/upasana26",
      },
    ],
  },
  {
    images: "31",
    title: "Tripti Thakur",
    designation: "Talent Acquisition Executive",
    socialNetwork: [],
  },
  {
    images: "32",
    title: "Sapna Yadav",
    designation: "Talent Acquisition Executive",
    socialNetwork: [],
  },
  {
    images: "33",
    title: "Shivani Pandey",
    designation: "Talent Acquisition Executive",
    socialNetwork: [],
  },
]

export default data
