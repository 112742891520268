import React, { Component } from "react"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"
import PageHelmet from "../../component/common/Helmet"
import Footer from "../../component/footer/Footer"
import Header from "../../component/header/Header"

class PayrollServices extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle='Payroll Services' />
        {/* End Pagehelmet  */}

        <Header
          headertransparent='header--transparent'
          colorblack='color--black'
          logoname='logo.png'
        />

        {/* Start Breadcrump Area */}
        <div
          className='rn-page-title-area pt--120 pb--190 bg_image bg_image--5'
          data-black-overlay='5'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='rn-page-title text-center pt--100'>
                  <h2 className='title theme-gradient'>Payroll Services</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className='rn-service-details ptb--120 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='service-details-inner'>
                  <div className='inner'>
                    {/* Start Single Area */}
                    <div className='row sercice-details-content align-items-center'>
                      <div className='col-lg-6 col-12 order-2 order-lg-1'>
                        <div className='details mt_md--30 mt_sm--30'>
                          <p>
                            In addition to keeping up with dynamic business
                            scenario and government regulations, payroll
                            processing proves to be a time-consuming and costly
                            tasks for most businesses.
                          </p>
                          <h5 className='title mt-2'>
                            Taking cue of your divergent business needs, Pyxidia
                            offers you the following benefits:
                          </h5>
                          <ul className='liststyle'>
                            <li>
                              A wide array of end to end service, starting from
                              payroll induction of new employees to final
                              settlement of leaving employees.
                            </li>
                            <li>
                              Speedy payroll processing, inclusive of
                              reimbursement claims.
                            </li>
                            <li>
                              Compliance to all statutory deductions and laws
                              while processing payroll.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-lg-6 col-12 order-1 order-lg-2'>
                        <div className='thumb position-relative'>
                          <img
                            className='w-100'
                            src='/assets/images/service/service-01.png'
                            alt='Service Images'
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }
}
export default PayrollServices
