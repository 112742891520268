import React, { Component } from "react"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"
import PageHelmet from "../../component/common/Helmet"
import Footer from "../../component/footer/Footer"
import Header from "../../component/header/Header"

class DirectStaffing extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle='Direct Staffing' />
        {/* End Pagehelmet  */}

        <Header
          headertransparent='header--transparent'
          colorblack='color--black'
          logoname='logo.png'
        />

        {/* Start Breadcrump Area */}
        <div
          className='rn-page-title-area pt--120 pb--190 bg_image bg_image--5'
          data-black-overlay='5'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='rn-page-title text-center pt--100'>
                  <h2 className='title theme-gradient'>Direct Staffing</h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className='rn-service-details ptb--120 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='service-details-inner'>
                  <div className='inner'>
                    {/* Start Single Area */}
                    <div className='row sercice-details-content align-items-center'>
                      <div className='col-lg-6 col-12 order-2 order-lg-1'>
                        <div className='details mt_md--30 mt_sm--30'>
                          <p>
                            At Pyxidia, our direct staffing services cater to
                            businesses of all sizes and sectors, providing a
                            solution to both lateral and volume hiring needs.
                            Our team of experienced recruiters work closely with
                            the client to understand the job requirements,
                            company culture, and business goals. This helps us
                            to find the right candidates who not only have the
                            right skills and qualifications but also align with
                            the company values.
                          </p>

                          <h5 className='title mt-2'>
                            Our direct staffing services include:
                          </h5>
                          <ul className='liststyle'>
                            <li>
                              Lateral Hiring: We help businesses to fill in the
                              crucial gaps in their existing teams with
                              experienced and skilled professionals.
                            </li>
                            <li>
                              Volume Hiring: Our team can efficiently handle
                              bulk hiring needs, ensuring a smooth and quick
                              process, while maintaining quality standards.
                            </li>
                          </ul>
                          <p className='mt-2'>
                            Our ultimate aim is to match the right candidate
                            with the right job, fostering long-term partnerships
                            between the employee and the employer.
                          </p>
                        </div>
                      </div>
                      <div className='col-lg-6 col-12 order-1 order-lg-2'>
                        <div className='thumb position-relative'>
                          <img
                            className='w-100'
                            src='/assets/images/service/service-01.png'
                            alt='Service Images'
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }
}
export default DirectStaffing
