import React, { Component } from "react"
import { FiChevronUp } from "react-icons/fi"
import ModalVideo from "react-modal-video"
import { Link } from "react-router-dom"
import ScrollToTop from "react-scroll-up"
import { VideoTag, videoTagString } from "react-video-tag"
import TeamOne from "../component/about/TeamOne"
import PageHelmet from "../component/common/Helmet"
import Footer from "../component/footer/Footer"
import Header from "../component/header/Header"
import BrandTwo from "../elements/BrandTwo"
import Testimonial from "../elements/Testimonial"
import CounterOne from "../elements/counters/CounterOne"
videoTagString({
  src: "/assets/images/service/video.mp4",
  poster: "/assets/images/bg/bg-image-24.jpg",
})

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    title: "About Us",
    description:
      "We are a global IT consulting and staffing firm that empowers businesses with a range of workforce and project-based solutions, serving over 100 Fortune 500 clients worldwide.",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
]

class About extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    let title = "About Us",
      description =
        "yxidia Techlab is owned by IT consulting, staffing & payroll services in India and has a footprint across the globe. Pyxidia empowers businesses through applied human intelligence and offers a spectrum of services that include Workforce Solutions (Contingent Staffing, Bulk/ Project Staffing, Master Vendor, RPO, Direct Hire and Payroll Transition) and Project-Based Solutions (Digital Experience, Technical Operations, Technical Development, Business Operations & Digital Platforms). Pyxidia Techlab works with over 100+ Fortune 500 clients across the USA, Canada, and India. "
    return (
      <React.Fragment>
        <PageHelmet pageTitle='About' />

        <Header />
        <div className='slider-wrapper'>
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className='slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center'
              key={index}
              data-black-overlay='6'
            >
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-lg-8'>
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      {value.title ? (
                        <h1 className='title'>{value.title}</h1>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className='description'>{value.description}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='video-inner'>
                      <ModalVideo
                        channel='youtube'
                        isOpen={this.state.isOpen}
                        videoId='xVMkyDSqlH4'
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button
                        className='video-popup theme-color'
                        onClick={this.openModal}
                      >
                        <span className='play-icon'></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='video-background'>
                <VideoTag
                  autoPlay={`${true}`}
                  muted={`${true}`}
                  playsInline={`${true}`}
                  loop={`${true}`}
                  src={`${"/assets/images/service/video.mp4"}`}
                  poster={`${"/assets/images/bg/bg-image-24.jpg"}`}
                />
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
        {/* Start Breadcrump Area */}
        {/* Start About Area  */}
        <div className='rn-about-area ptb--120 bg_color--1'>
          <div className='rn-about-wrapper'>
            <div className='container'>
              <div className='row row--35 align-items-center'>
                <div className='col-lg-5'>
                  <div className='thumbnail'>
                    <img
                      className='w-100'
                      src='/assets/images/about/about-3.jpg'
                      alt='About Images'
                    />
                  </div>
                </div>
                <div className='col-lg-7'>
                  <div className='about-inner inner'>
                    <div className='section-title'>
                      {/* <h2 className='title'>{title}</h2> */}
                      <p className='description'>
                        {" "}
                        <span style={{ fontSize: "36px" }}>P</span>
                        {description}
                      </p>
                    </div>
                    <div className='row mt--30'>
                      <div className='col-12'>
                        <div className='about-us-list'>
                          <h3 className='title'>Who we are</h3>
                          <p>
                            Our company is dedicated to helping you to achieve
                            your IT Business Management objectives, Payroll &
                            compliance, Resource Augmentation, Contract &
                            Full-Time Hiring.
                            <br />
                            Using our many years of Business Management
                            experience, we will enable you through education,
                            mentoring and practical examples to discover the
                            one-roof solution for your IT requirements, HR
                            compliances, Payroll services & Resource hiring for
                            contract & full-time positions.
                            <br />
                            We will provide you with our needed services to
                            exclusively support your business and to determine
                            how best to achieve them.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area */}
        <div className='rn-counterup-area pt--110 pb--120 bg_color--5'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title text-center'>
                  <h3 className='fontWeight500'>We Achieved</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}
        <div className='rn-columns-area ptb--120 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-12'>
                <div className='single-column'>
                  <h3
                    className='tilte'
                    style={{
                      fontSize: "36px",
                      lineHeight: "26px",
                    }}
                  >
                    Purpose
                  </h3>
                  <p>
                    To enable clients’ business growth and strengthening by
                    supporting them to focus on their core activities, At
                    Pyxidia Techlab, we partner with our clients to expand their
                    business potential by providing the best solutions.
                  </p>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-12 mt_sm--30'>
                <div className='single-column'>
                  <h4
                    className='tilte'
                    style={{
                      fontSize: "36px",
                      lineHeight: "26px",
                    }}
                  >
                    Vision
                  </h4>
                  <p>
                    To become a nationwide consulting service provider rendering
                    innovative solutions day in and day out…
                  </p>
                </div>
              </div>
            </div>
            <div className='row pt--30'>
              <div className='col-lg-6 col-md-6 col-12'>
                <div className='single-column'>
                  <h4
                    className='tilte'
                    style={{
                      fontSize: "36px",
                      lineHeight: "26px",
                    }}
                  >
                    Mission
                  </h4>
                  <p>
                    To provide all the business-related consulting services
                    under one roof as per the business requirement…
                  </p>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-12 mt_sm--30'>
                <div className='single-column'>
                  <h4
                    className='tilte'
                    style={{
                      fontSize: "36px",
                      lineHeight: "26px",
                    }}
                  >
                    Values
                  </h4>
                  <p>
                    Since Pyxidia Techlab LLP’s inception, our core values are
                    an elemental and fundamental guide of our path…
                  </p>
                </div>
              </div>
            </div>
            <div className='row pt--30'>
              <div className='col-lg-12'>
                <div className='single-column'>
                  <br />

                  <h4
                    className='tilte'
                    style={{
                      fontSize: "36px",
                      lineHeight: "35px",
                    }}
                  >
                    Our Brand Philosophy
                  </h4>
                  <br />
                  <p>
                    We believe intelligence is a combination of technology and
                    human ability translated into intelligent solutions.
                    <br />
                    Today, the organization needs a workforce that can provide
                    intelligent business solutions. While talent needs
                    opportunities to apply their intellect and progress in their
                    career. We empower both through applied human intelligence.
                    <br />
                    For us, intelligence is a way of life. Our Talent Promise At
                    Pyxidia Techlab, we are empowering talent by connecting
                    potential with opportunities through applied human
                    intelligence. We empower our teams to maximize the impact of
                    their intellect, through a performance-oriented, diverse,
                    flexible, and inclusive work environment supported by our
                    continuous learning and development focus. Led by our
                    visionary leadership, fuelled by our values, and driven by
                    our combined intellect, our teams work with some of the
                    largest Fortune 500 clients, defining industry benchmarks
                    while optimizing their careers.
                    <br />
                    Pyxidia’s mission is to be the ecosystem connecting people,
                    technology, and opportunities through applied human
                    intelligence. Vision Transforming our world by empowering
                    global businesses through applied human intelligence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Finding Us Area  */}
        <div className='rn-finding-us-area rn-finding-us bg_color--1'>
          <div className='inner'>
            <div className='content-wrapper'>
              <div className='content'>
                <h4 className='theme-gradient'>Find Your Work Now</h4>
                <p>
                  Expert IT consultants, efficient payroll management, all under
                  one roof. Your one-stop-shop for all things tech.
                </p>
                <Link className='rn-btn btn-white' href='/contact'>
                  Get Started
                </Link>
              </div>
            </div>
            <div className='thumbnail'>
              <div className='image'>
                <img
                  src='/assets/images/about/finding-us-01.png'
                  alt='Finding Images'
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Finding Us Area  */}

        {/* Start Team Area  */}
        <div className='rn-team-area bg_color--1 ptb--120'>
          <div className='container'>
            <TeamOne
              column='col-lg-4 col-md-4 col-sm-6 col-12'
              teamStyle='team-style--bottom'
              item='8'
            />
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start Testimonial Area */}
        <div className='rn-testimonial-area bg_color--5 ptb--120'>
          <div className='container'>
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Brand Area */}
        <div className='rn-brand-area brand-separation bg_color--5 ptb--120'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }
}
export default About
