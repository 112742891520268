import React, { Component } from "react"
import PageHelmet from "../component/common/Helmet"
import ModalVideo from "react-modal-video"
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa"
import ScrollToTop from "react-scroll-up"
import {
  FiBriefcase,
  FiChevronUp,
  FiDollarSign,
  FiFileText,
  FiUsers,
} from "react-icons/fi"
import Header from "../component/header/Header"
import Footer from "../component/footer/Footer"
import ImageList from "../component/project/ImageList"
import { Link } from "react-router-dom"

const ServiceList = [
  {
    title: "Human Resources Manager",
    route: "https://www.linkedin.com/jobs/view/3476878836/",
    location: "Raipur, Chhattisgarh, India On-site",
  },
  {
    title: "Talent Acquisition Specialist",
    route: "https://www.linkedin.com/jobs/view/3482373205/",
    location: "Bengaluru, Karnataka, India On-site",
  },
  {
    title: "Java Developer",
    route:
      "https://www.linkedin.com/posts/pyxidiatechlab_team-leadership-work-activity-7015586243694473216-VtmS/?utm_source=share&utm_medium=member_android",
    location: "Remote",
  },
]
class Jobs extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle='Jobs' />

        <Header
          headertransparent='header--transparent'
          colorblack='color--black'
          logoname='logo.png'
        />

        {/* Start Breadcrump Area */}
        <div
          className='rn-page-title-area pt--120 pb--190 bg_image bg_image--5'
          data-black-overlay='7'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='rn-page-title text-center pt--100'>
                  <h2 className='title theme-gradient'>Join our Team</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className='rn-portfolio-details ptb--120 bg_color--3'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='portfolio-details'>
                  <div className='inner'>
                    <h2>Who we are</h2>
                    <p className='subtitle'>
                      Our company is dedicated to helping you to achieve your IT
                      Business Management objectives, Payroll & compliance,
                      Resource Augmentation, Contract & Full-Time Hiring. Using
                      our many years of Business Management experience, we will
                      enable you through education, mentoring and practical
                      examples to discover the one-roof solution for your IT
                      requirements, HR compliances, Payroll services & Resource
                      hiring for contract & full-time positions.
                    </p>
                    <p>
                      We will provide you with our needed services to
                      exclusively support your business and to determine how
                      best to achieve them.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='service-area creative-service-wrapper pt--90 pb--120 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title text-center mb--30'>
                  <h2>We Are Hiring</h2>
                </div>
              </div>
            </div>
            <div className='row'>
              {ServiceList.map((val, i) => (
                <div
                  className=' col-12'
                  key={i}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "2px solid grey",
                    borderRadius: "5px",
                    margin: "5px",
                    marginBottom: "10px",
                    padding: "30px",
                  }}
                >
                  <div>
                    <h3 className='title'>{val.title}</h3>
                    <p className='subtitle'>{val.location}</p>
                  </div>
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <a
                      className='rn-button-style--2 btn-solid'
                      href={val.route}
                    >
                      view
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}
        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }
}
export default Jobs
