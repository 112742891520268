import React, { Component } from "react"
import Slider from "react-slick"
import { portfolioSlick2 } from "../../page-demo/script"

const PortfolioList = [
  {
    image: "gallery-1",
  },
  {
    image: "gallery-2",
  },
  {
    image: "gallery-3",
  },
  {
    image: "gallery-4",
  },
  {
    image: "gallery-5",
  },
  {
    image: "gallery-6",
  },
  {
    image: "gallery-7",
  },
  {
    image: "gallery-8",
  },
  {
    image: "gallery-9",
  },
]

class ImageList extends Component {
  render() {
    return (
      <React.Fragment>
        <div className='portfolio-wrapper'>
          <div className='portfolio-slick-activation mt--70 mt_sm--40'>
            <Slider {...portfolioSlick2}>
              {PortfolioList.map((value, index) => (
                <div className='portfolio' key={index}>
                  <div className='thumbnail-inner'>
                    <div className={`thumbnail ${value.image}`}></div>
                    <div className={`bg-blr-image ${value.image}`}></div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default ImageList
