// React Required
import React, { Component } from "react"
import ReactDOM from "react-dom"

// Create Import File
import "./fonts.css"
import "./index.scss"
import PageScrollTop from "./component/PageScrollTop"

// Home layout
import Demo from "./page-demo/Demo"
import MainDemo from "./home/MainDemo"
import Startup from "./home/Startup"
import Paralax from "./home/Paralax"
import HomePortfolio from "./home/HomePortfolio"
import DigitalAgency from "./home/DigitalAgency"
import CreativeAgency from "./home/CreativeAgency"
import PersonalPortfolio from "./home/PersonalPortfolio"
import Business from "./home/Business"
import StudioAgency from "./home/StudioAgency"
import PortfolioLanding from "./home/PortfolioLanding"
import CreativeLanding from "./home/CreativeLanding"
import HomeParticles from "./home/HomeParticles"
import CreativePortfolio from "./home/CreativePortfolio"
import DesignerPortfolio from "./home/DesignerPortfolio"
import InteriorLanding from "./home/Interior"
import CorporateBusiness from "./home/CorporateBusiness"
import InteractiveAgency from "./home/InteractiveAgency"

// Dark Home Layout
import DarkMainDemo from "./dark/MainDemo"
import DarkPortfolioLanding from "./dark/PortfolioLanding"

// Element Layout
import Service from "./elements/Service"
import ServiceDetails from "./elements/ServiceDetails"
import About from "./elements/About"
import Contact from "./elements/Contact"
import PortfolioDetails from "./elements/PortfolioDetails"
import Blog from "./elements/Blog"
import BlogDetails from "./elements/BlogDetails"
import error404 from "./elements/error404"

// Blocks Layout

import Team from "./blocks/Team"
import Counters from "./blocks/Counters"
import Testimonial from "./blocks/Testimonial"
import Portfolio from "./blocks/Portfolio"
import VideoPopup from "./blocks/VideoPopup"
import Gallery from "./blocks/Gallery"
import Brand from "./blocks/Brand"
import ProgressBar from "./blocks/ProgressBar"
import ContactForm from "./blocks/ContactForm"
import GoogleMap from "./blocks/GoogleMap"
import Columns from "./blocks/Columns"
import PricingTable from "./blocks/PricingTable"
import { Switch, Route, HashRouter, BrowserRouter } from "react-router-dom"
import * as serviceWorker from "./serviceWorker"
import ComplianceServices from "./elements/services/ComplianceServices"
import ContractStaffing from "./elements/services/ContractStaffing"
import ProjectBasedHiring from "./elements/services/ProjectBasedHiring"
import PayrollServices from "./elements/services/PayrollServices"
import DirectStaffing from "./elements/services/DirectStaffing"
import ITConsultingServices from "./elements/services/ITConsultingServices"
import BlogDetails1 from "./elements/blogs/BlogDetails1"
import BlogDetails2 from "./elements/blogs/BlogDetails2"
import BlogDetails3 from "./elements/blogs/BlogDetails3"
import BlogDetails4 from "./elements/blogs/BlogDetails4"
import BlogDetails5 from "./elements/blogs/BlogDetails5"
import BlogDetails6 from "./elements/blogs/BlogDetails6"
import Jobs from "./home/Jobs"
import BPOServices from "./elements/services/BPOServices"

class Root extends Component {
  render() {
    return (
      <HashRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route exact path='/' component={DigitalAgency} />
            <Route exact path='/home' component={DigitalAgency} />
            <Route exact path='/service' component={Service} />
            <Route exact path='/service-details' component={ServiceDetails} />
            <Route
              exact
              path='/service/compliance-services'
              component={ComplianceServices}
            />
            <Route
              exact
              path='/service/contract-staffing'
              component={ContractStaffing}
            />
            <Route
              exact
              path='/service/project-based-hiring'
              component={ProjectBasedHiring}
            />
            <Route
              exact
              path='/service/payroll-services'
              component={PayrollServices}
            />
            <Route
              exact
              path='/service/direct-staffing'
              component={DirectStaffing}
            />
            <Route
              exact
              path='/service/it-consulting-services'
              component={ITConsultingServices}
            />
            <Route exact path='/service/bpo-services' component={BPOServices} />
            <Route exact path='/about' component={About} />
            <Route exact path='/contact' component={Contact} />
            <Route
              exact
              path='/portfolio-details'
              component={PortfolioDetails}
            />
            <Route exact path={`/blog`} component={Blog} />
            <Route
              exact
              path='/blogs/x-ray-search-linkedIn'
              component={BlogDetails1}
            />
            <Route
              exact
              path='/blogs/boolean-search'
              component={BlogDetails2}
            />
            <Route
              exact
              path='/blogs/passive-candidate'
              component={BlogDetails3}
            />
            <Route exact path='/blogs/it-market' component={BlogDetails4} />
            <Route exact path='/jobs' component={Jobs} />
            <Route
              exact
              path='/blogs/interview-it-industry'
              component={BlogDetails6}
            />
            <Route path='/404' component={error404} />
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </HashRouter>
    )
  }
}

ReactDOM.render(<Root />, document.getElementById("root"))
serviceWorker.register()
