import React from "react"

function ContactForm() {
  return (
    <form
      className='contact-form'
      action='https://api.web3forms.com/submit'
      method='POST'
      id='form'
    >
      <input
        type='hidden'
        name='access_key'
        value='b30b8141-0b66-4705-962e-d198f3c4711a'
        // value='8ef85cfe-aa68-47df-bf03-152ca857caa2' //testing
      />
      <input
        type='hidden'
        name='redirect'
        value='https://web3forms.com/success'
      />
      <input
        type='checkbox'
        name='botcheck'
        id=''
        style={{ display: "none" }}
      />
      <div className='rn-form-group'>
        <input name='name' type='text' placeholder='Enter your name' />
      </div>
      <div className='rn-form-group'>
        <input name='email' type='email' placeholder='Enter your email' />
      </div>
      <div className='rn-form-group'>
        <input name='phone' type='text' placeholder='Enter your Phone' />
      </div>
      <div className='rn-form-group'>
        <input name='subject' type='text' placeholder='Enter Subject' />
      </div>
      <div name='message' className='rn-form-group'>
        <textarea
          name='message'
          rows={5}
          placeholder='Your message'
          defaultValue={""}
        />
      </div>

      <div className='rn-form-group'>
        <button
          className='rn-button-style--2 btn-solid'
          type='submit'
          value='submit'
          name='submit'
          id='mc-embedded-subscribe'
        >
          Submit Now
        </button>
      </div>
    </form>
  )
}
export default ContactForm
