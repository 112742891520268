import React, { useState, useEffect } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

const Testimonial = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [tabCount] = useState(4) // set the number of tabs

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((activeTab + 1) % tabCount)
    }, 5000)
    return () => clearInterval(interval)
  }, [activeTab, tabCount])

  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-lg-12'>
          <Tabs
            selectedIndex={activeTab}
            onSelect={(index) => setActiveTab(index)}
          >
            <TabPanel>
              <div className='rn-testimonial-content text-center'>
                <div className='inner test_inner_height'>
                  <p className='test_p_1'>
                    I had pleasure of working with Pyxidia on a hiring project
                    and was extremely satisfied with quality of work. He&#39;s
                    professional, committed and tenacious. I would definitely
                    recommend his services with high NPS.
                  </p>
                </div>
                <div className='author-info'>
                  <h6>
                    <span>Raksha Srinivasan </span> - NoBroker - Senior HR
                    Manager
                  </h6>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className='rn-testimonial-content text-center'>
                <div className='inner test_inner_height'>
                  <p className='test_p_2'>
                    Infinx benefited from the timely contribution of smart
                    people from Pyxidiatech Labs. At Infinx ‘when we started
                    development on new product and interviewed many people to
                    help us with complex requirement to co-develop, we were
                    referred some of the brightest engineers from Pyxidiatech
                    Labs, which eventually helped us to form a team wiith
                    developers and QA. What transpired eventually was a world
                    class product which was launched on time with the complexity
                    addressed of designing a highly generic data processing
                    solution and display while also targeting another goal of
                    ingesting data within agreed SLA. None of these could have
                    been possible had we not got the right team assembled with
                    the right attitude and aptitude. Looking forward to ‘work
                    with Pyxidiatech Labs for a longer duration for the benefit
                    of the product and wish them all the best for all future
                    endeavours.
                  </p>
                </div>
                <div className='author-info'>
                  <h6>
                    <span>Abhijit Sinha</span>- Infinx - Technical Director and
                    Stakeholder
                  </h6>
                </div>
              </div>
            </TabPanel>
            {/* <TabPanel>
              <div className='rn-testimonial-content text-center'>
                <div className='inner test_inner_height'>
                  <p className='test_p_3'>
                    Pyxidiatech provided exceptional service from start to
                    finish. Their team was friendly, knowledgeable, and took the
                    time to understand our specific needs for the open position.
                    They provided a list of perfectly suited candidates and
                    offered helpful advice and guidance throughout the
                    recruitment process. We highly recommend Pyxidiatech for
                    finding the right resource for your open position.
                  </p>
                </div>
                <div className='author-info'>
                  <h6>
                    <span>Jeff Miller</span>- Paralucent - Founder &amp; CEO
                  </h6>
                </div>
              </div>
            </TabPanel> */}

            <TabPanel>
              <div className='rn-testimonial-content text-center'>
                <div className='inner test_inner_height'>
                  <p className='test_p_4'>
                    A fantastic organization! Great support from beginning to
                    end of the process. The team are really informed and go the
                    extra mile at every stage. I would recommend them
                    unreservedly.
                  </p>
                </div>
                <div className='author-info'>
                  <h6>
                    <span>Ravindra Eluri</span> - Andortech India Pvt Ltd - Vice
                    President
                  </h6>
                </div>
              </div>
            </TabPanel>
            <TabList className='testimonial-thumb-wrapper'>
              <Tab>
                <div className='testimonial-thumbnai'>
                  <div className='thumb'>
                    <img
                      src='/assets/images/client/client-1.png'
                      alt='Testimonial Images'
                    />
                  </div>
                </div>
              </Tab>
              <Tab>
                <div className='testimonial-thumbnai'>
                  <div className='thumb'>
                    <img
                      src='/assets/images/client/client-2.png'
                      alt='Testimonial Images'
                    />
                  </div>
                </div>
              </Tab>
              {/* <Tab>
                <div className='testimonial-thumbnai'>
                  <div className='thumb'>
                    <img
                      src='/assets/images/client/client-3.png'
                      alt='Testimonial Images'
                    />
                  </div>
                </div>
              </Tab> */}
              <Tab>
                <div className='testimonial-thumbnai'>
                  <div className='thumb'>
                    <img
                      src='/assets/images/client/client-4.png'
                      alt='Testimonial Images'
                    />
                  </div>
                </div>
              </Tab>
            </TabList>
          </Tabs>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Testimonial
