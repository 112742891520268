import React, { Component } from "react"

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className='brand-style-2'>
          <li>
            <img src='/assets/images/brand/brand-01.png' alt='Logo Images' />
          </li>
          <li>
            <img src='/assets/images/brand/brand-02.png' alt='Logo Images' />
          </li>
          <li>
            <img src='/assets/images/brand/brand-03.png' alt='Logo Images' />
          </li>
          <li>
            <img src='/assets/images/brand/brand-04.png' alt='Logo Images' />
          </li>
          <li>
            <img src='/assets/images/brand/brand-05.png' alt='Logo Images' />
          </li>
          <li>
            <img src='/assets/images/brand/brand-06.png' alt='Logo Images' />
          </li>
          {/* <li>
            <img src='/assets/images/brand/brand-07.png' alt='Logo Images' />
          </li> */}
          <li>
            <img src='/assets/images/brand/brand-08.png' alt='Logo Images' />
          </li>
          <li>
            <img src='/assets/images/brand/brand-09.png' alt='Logo Images' />
          </li>
        </ul>
      </React.Fragment>
    )
  }
}
export default BrandTwo
