import React, { Component } from "react"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"
import PageHelmet from "../../component/common/Helmet"
import Footer from "../../component/footer/Footer"
import Header from "../../component/header/Header"

class ContractStaffing extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle='Contract Staffing' />
        {/* End Pagehelmet  */}

        <Header
          headertransparent='header--transparent'
          colorblack='color--black'
          logoname='logo.png'
        />

        {/* Start Breadcrump Area */}
        <div
          className='rn-page-title-area pt--120 pb--190 bg_image bg_image--5'
          data-black-overlay='5'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='rn-page-title text-center pt--100'>
                  <h2 className='title theme-gradient'>Contract Staffing</h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className='rn-service-details ptb--120 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='service-details-inner'>
                  <div className='inner'>
                    {/* Start Single Area */}
                    <div className='row sercice-details-content align-items-center'>
                      <div className='col-lg-6 col-12 order-2 order-lg-1'>
                        <div className='details mt_md--30 mt_sm--30'>
                          <p>
                            At Pyxidia, we understand that businesses are facing
                            unique challenges in today's dynamic job market.
                            With the ever-changing demands of the industry,
                            companies are often in need of a skilled workforce
                            to navigate the ups and downs of their businesses.
                            That's why we offer temporary staffing solutions to
                            help organizations of all sizes find the right
                            talent for their needs.
                          </p>
                          <p>
                            We make the process of temporary staffing
                            hassle-free and smooth for our clients, providing
                            them with access to a pool of highly skilled and
                            qualified employees. Our staffing solutions are
                            tailored to meet the unique requirements of each
                            organization, ensuring that we provide the right fit
                            for the right job.
                          </p>
                          <h5 className='title mt-2'>
                            Benefits of choosing Pyxidia for your temporary
                            staffing needs include:
                          </h5>
                          <ul className='liststyle'>
                            <li>
                              A wide pool of highly skilled and qualified
                              candidates to choose from
                            </li>
                            <li>Hassle-free and smooth staffing process</li>
                            <li>
                              Tailored staffing solutions to meet the unique
                              requirements of your organization
                            </li>
                            <li>
                              Access to top talent to help your business succeed
                              in today's dynamic job market.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-lg-6 col-12 order-1 order-lg-2'>
                        <div className='thumb position-relative'>
                          <img
                            className='w-100'
                            src='/assets/images/service/service-01.png'
                            alt='Service Images'
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }
}
export default ContractStaffing
