import React, { Component } from "react"
import PageHelmet from "../component/common/Helmet"
import Breadcrumb from "../elements/common/Breadcrumb"
import {
  FiUsers,
  FiChevronUp,
  FiBriefcase,
  FiFileText,
  FiDollarSign,
  FiGlobe,
  FiUser,
} from "react-icons/fi"
import ScrollToTop from "react-scroll-up"
import Header from "../component/header/Header"
import Footer from "../component/footer/Footer"
import { Link } from "react-router-dom"

const ServiceList = [
  {
    icon: <FiBriefcase />,
    title: "Compliance Services",
    route: "service/compliance-services",
    description:
      "Let us help your company meet government standards with our expert compliance services.",
  },
  {
    icon: <FiFileText />,
    title: "Contract Staffing",
    route: "service/contract-staffing",
    description:
      "Streamline your workforce with Pyxidia's hassle-free temporary staffing solutions, providing skilled and qualified employees to meet your changing business needs.",
  },
  {
    icon: <FiUsers />,
    title: "Project Based Hiring",
    route: "service/project-based-hiring",
    description:
      "Maximize flexibility and efficiency with Pyxidia's project-based hiring services, allowing you to seamlessly scale your team to meet the demands of your business and projects.",
  },
  {
    icon: <FiDollarSign />,
    title: "Payroll Services",
    route: "service/payroll-services",
    description:
      "Simplify payroll with Pyxidia's comprehensive and efficient payroll processing services, including full-spectrum induction to settlement, prompt processing, and guaranteed compliance with statutory laws.",
  },
  {
    icon: <FiUser />,
    title: "Direct Staffing ( Lateral & Volume Hiring)",
    route: "service/direct-staffing",
    description:
      "Find the perfect fit with Pyxidia's Direct Staffing services, connecting skilled professionals with the best job opportunities.",
  },
  {
    icon: <FiGlobe />,
    title: "IT Consulting & Services",
    route: "service/it-consulting-services",
    description:
      "BPO is the practice of hiring an external company to handle non-core business processes, including customer service, back office operations, finance, legal, healthcare, and IT, allowing companies to focus on their core competencies.",
  },
  {
    icon: <FiUsers />,
    title: "BPO Services",
    route: "service/bpo-services",
    description:
      "Transform your business with Pyxidia's IT Consulting & Services, delivering cutting-edge solutions across Workforce Solutions and Project-Based Solutions, powered by the expertise of Pyxidia Techlab",
  },
]
class Service extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle='Service' />
        <Header
          headertransparent='header--transparent'
          colorblack='color--black'
          logoname='logo.png'
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"OWN YOUR SOLUTION"} />
        {/* End Breadcrump Area */}

        {/* Start Service Area */}
        <div className='service-area creative-service-wrapper pt--90 pb--120 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title text-center mb--30'>
                  <h2>We Provide</h2>
                </div>
              </div>
            </div>
            <div className='row creative-service'>
              {ServiceList.map((val, i) => (
                <div
                  className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'
                  key={i}
                >
                  <Link className='text-center' to={val.route}>
                    <div className='service service__style--2'>
                      <div className='icon'>{val.icon}</div>
                      <div className='content'>
                        <h3 className='title'>{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }
}
export default Service
