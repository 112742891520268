import React, { Component } from "react"
import { FiUsers, FiFileText, FiDollarSign, FiBriefcase } from "react-icons/fi"
import { Link } from "react-router-dom"

const ServiceList = [
  {
    icon: <FiBriefcase />,
    title: "Compliance Services",
    route: "service/compliance-services",
    description:
      "Let us help your company meet government standards with our expert compliance services.",
  },
  {
    icon: <FiFileText />,
    title: "Contract Staffing",
    route: "service/contract-staffing",
    description:
      "Streamline your workforce with Pyxidia's hassle-free temporary staffing solutions, providing skilled and qualified employees to meet your changing business needs.",
  },
  {
    icon: <FiUsers />,
    title: "Project Based Hiring",
    route: "service/project-based-hiring",
    description:
      "Maximize flexibility and efficiency with Pyxidia's project-based hiring services, allowing you to seamlessly scale your team to meet the demands of your business and projects.",
  },
  {
    icon: <FiDollarSign />,
    title: "Payroll Services",
    route: "service/payroll-services",
    description:
      "Simplify payroll with Pyxidia's comprehensive and efficient payroll processing services, including full-spectrum induction to settlement, prompt processing, and guaranteed compliance with statutory laws.",
  },
]

class ServiceTwo extends Component {
  render() {
    let title = "OWN YOUR SOLUTION",
      description = "Here are The few Services we Expertise in!"
    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-lg-4 col-12'>
            <div className='section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10'>
              <h2 className='title'>{title}</h2>
              <p>{description}</p>
              <div className='service-btn'>
                <Link
                  className='btn-transparent rn-btn-dark'
                  to='/contact#connect'
                >
                  <span className='text'>Request Custom Service</span>
                </Link>
              </div>
              <Link
                className='rn-button-style--2 btn-solid mt--30'
                to='/service'
              >
                <span>View More</span>
              </Link>
            </div>
          </div>
          <div className='col-lg-8 col-12 mt_md--50'>
            <div className='row service-one-wrapper'>
              {ServiceList.map((val, i) => (
                <div className='col-lg-6 col-md-6 col-sm-6 col-12' key={i}>
                  <Link to={val.route}>
                    <div className='service service__style--2'>
                      <div className='icon'>{val.icon}</div>
                      <div className='content'>
                        <h3 className='title'>{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default ServiceTwo
