import React, { Component } from "react"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"
import PageHelmet from "../../component/common/Helmet"
import Footer from "../../component/footer/Footer"
import Header from "../../component/header/Header"

class BPOServices extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle='  BPO Services' />
        {/* End Pagehelmet  */}

        <Header
          headertransparent='header--transparent'
          colorblack='color--black'
          logoname='logo.png'
        />

        {/* Start Breadcrump Area */}
        <div
          className='rn-page-title-area pt--120 pb--190 bg_image bg_image--5'
          data-black-overlay='5'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='rn-page-title text-center pt--100'>
                  <h2 className='title theme-gradient'>BPO Services</h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className='rn-service-details ptb--120 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='service-details-inner'>
                  <div className='inner'>
                    {/* Start Single Area */}
                    <div className='row sercice-details-content align-items-center'>
                      <div className='col-lg-6 col-12 order-2 order-lg-1'>
                        <div className='details mt_md--30 mt_sm--30'>
                          <p>
                            BPO, or Business Process Outsourcing, refers to the
                            practice of hiring an external company to handle
                            some or all of the business processes of another
                            company. The types of BPO services can vary
                            depending on the needs of the company, but some
                            common types include:
                          </p>
                          <p>
                            Customer Service Outsourcing: This involves
                            outsourcing customer support and service-related
                            tasks such as responding to inquiries, resolving
                            complaints, and providing technical support.
                          </p>
                          <p>
                            Back Office Outsourcing: This includes tasks related
                            to internal business operations such as accounting,
                            human resources, payroll, and data entry.
                          </p>
                          <p>
                            Finance and Accounting Outsourcing: This involves
                            outsourcing finance and accounting tasks such as
                            bookkeeping, tax preparation, and financial
                            analysis.
                          </p>
                          <p>
                            Knowledge Process Outsourcing: This type of BPO
                            involves outsourcing knowledge-intensive tasks such
                            as research and development, data analysis, and
                            engineering.
                          </p>
                          <p>
                            Legal Process Outsourcing: This involves outsourcing
                            legal tasks such as contract management, legal
                            research, and document review.
                          </p>
                          <p>
                            Healthcare BPO: This involves outsourcing
                            healthcare-related tasks such as medical billing,
                            medical coding, and medical transcription.
                          </p>
                          <p>
                            IT Outsourcing: This includes outsourcing IT-related
                            tasks such as software development, network
                            management, and technical support.
                          </p>
                          <p>
                            Overall, BPO services allow companies to focus on
                            their core competencies while delegating non-core
                            processes to external experts who can often provide
                            the services more efficiently and cost-effectively
                          </p>
                        </div>
                      </div>
                      <div className='col-lg-6 col-12 order-1 order-lg-2'>
                        <div className='thumb position-relative'>
                          <img
                            className='w-100'
                            src='/assets/images/service/service-01.png'
                            alt='Service Images'
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }
}
export default BPOServices
