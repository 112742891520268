import React, { Component, Fragment } from "react"
import Slider from "react-slick"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"
import { portfolioSlick2, slideSlick } from "../page-demo/script"
import ServiceList from "../elements/service/ServiceList"
import Header from "../component/header/Header"
import FooterTwo from "../component/footer/FooterTwo"
import Testimonial from "../elements/Testimonial"
import PortfolioList from "../elements/portfolio/PortfolioList"
import BlogContent from "../elements/blog/BlogContent"
import Brand from "../elements/Brand"
import CallAction from "../elements/callaction/CallAction"
import TabOne from "../elements/tab/TabOne"
import Helmet from "../component/common/Helmet"
import ServiceTwo from "../elements/service/ServiceTwo"
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo"
import ModalVideo from "react-modal-video"
import Particles from "react-tsparticles"
import CounterOne from "../elements/counters/CounterOne"
import { Link } from "react-router-dom"
import ImageList from "../component/project/ImageList"
import BrandTwo from "../elements/BrandTwo"

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Pyxidia",
    title2: "Techlab",
    description: "A Dependable Professional Partnership",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
]
const PortfolioList2 = [
  {
    image: "image-1",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-2",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: " Getting tickets to the big show",
  },
]
class DigitalAgency extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  render() {
    return (
      <Fragment className='fix_width'>
        <Helmet pageTitle='PYXIDIA' />

        {/* Start Header Area  */}
        <Header logo='light' color='color-white' />
        {/* End Header Area  */}
        <div>
          <div
            className='slider-activation slider-creative-agency with-particles'
            id='home'
          >
            <div className='frame-layout__particles'>
              <Particles
                className='particle'
                options={{
                  style: {
                    position: "absolute",
                  },
                  fpsLimit: 100,
                  interactivity: {
                    detectsOn: "canvas",
                    events: {
                      onClick: {
                        enable: true,
                        mode: "push",
                      },
                      onHover: {
                        enable: true,
                        mode: "repulse",
                      },
                      resize: true,
                    },
                    modes: {
                      bubble: {
                        distance: 100,
                        duration: 2,
                        opacity: 0.8,
                        size: 10,
                        color: "#888",
                      },
                      push: {
                        quantity: 4,
                      },
                      repulse: {
                        distance: 100,
                        duration: 0.4,
                        color: "#888",
                      },
                    },
                  },
                  particles: {
                    color: {
                      value: "#888",
                    },
                    links: {
                      color: "#888",
                      distance: 150,
                      enable: true,
                      opacity: 0.5,
                      width: 1,
                    },
                    collisions: {
                      enable: true,
                    },
                    move: {
                      direction: "none",
                      enable: true,
                      outMode: "bounce",
                      random: false,
                      speed: 6,
                      straight: false,
                    },
                    number: {
                      density: {
                        enable: true,
                        value_area: 2000,
                      },
                      value: 80,
                    },
                    opacity: {
                      value: 0.5,
                    },
                    shape: {
                      type: "circle",
                    },
                    size: {
                      random: true,
                      value: 5,
                    },
                  },
                  detectRetina: true,
                }}
              />
            </div>
            <div className='bg_image bg_image--35' style={{ height: "99vh" }}>
              {SlideList.map((value, index) => (
                <div
                  className='slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center'
                  key={index}
                >
                  <div className='container' style={{ marginTop: "50px" }}>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          <h1 className='title title_text'>
                            {value.title ? (
                              <span style={{ color: "#e88c11" }}>
                                {value.title}
                              </span>
                            ) : (
                              ""
                            )}
                            {value.title2 ? (
                              <span style={{ color: "white" }}>
                                &nbsp;{value.title2}
                              </span>
                            ) : (
                              ""
                            )}
                          </h1>
                          {value.description ? (
                            <p className='description'>
                              <i>{value.description}</i>
                            </p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className='slide-btn'>
                              <Link
                                className='rn-button-style--2 btn-primary-color'
                                to={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Start Blog Details */}
          <div className='rn-blog-details pt--70 pb--70 bg_color--1'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='inner-wrapper'>
                    <div className='inner'>
                      <p style={{ fontFamily: "'Philosopher'" }}>
                        <span
                          style={{
                            fontSize: "40px",
                            fontFamily: '"Prata rev=1"',
                            color: "rgb(232, 140, 17)",
                            fontWeight: "600",
                            lineHeight: "50px",
                          }}
                        >
                          Pyxidia Techlab
                        </span>
                        &nbsp; is owned by IT consulting, staffing & payroll
                        services in India and has a footprint across the globe.
                        Pyxidia empowers businesses through applied human
                        intelligence and offers a spectrum of services that
                        include Workforce Solutions{" "}
                        <b>
                          (Contingent Staffing, Bulk/ Project Staffing, Master
                          Vendor, RPO, Direct Hire and Payroll Transition)
                        </b>{" "}
                        and Project-Based Solutions{" "}
                        <b>
                          (Digital Experience, Technical Operations, Technical
                          Development, Business Operations & Digital Platforms)
                        </b>
                        . Pyxidia Techlab works with over 100+ Fortune 500
                        clients across the USA, Canada, and India.
                      </p>
                      <div className='blog-single-list-wrapper d-flex flex-wrap mb--70 '>
                        <div className='thumbnail'>
                          <img
                            className='w-100'
                            src='/assets/images/service/service-03.png'
                            alt='BLog Images'
                          />
                          {/* <span>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do
                        </span> */}
                        </div>
                        <div className='content'>
                          <h4
                            className='title mt-4'
                            style={{
                              fontSize: "42px",
                              fontFamily: '"Prata rev=1"',
                              fontWeight: "600",
                            }}
                          >
                            Who Are We?
                          </h4>
                          <p>
                            Our company is dedicated to helping you to achieve
                            your IT Business Management objectives, Payroll &
                            compliance, Resource Augmentation, Contract &
                            Full-Time Hiring.
                            <br />
                            <br />
                            Using our many years of Business Management
                            experience, we will enable you through education,
                            mentoring and practical examples to discover the
                            one-roof solution for your IT requirements, HR
                            compliances, Payroll services & Resource hiring for
                            contract & full-time positions.
                            <br />
                            <br />
                            We will provide you with our needed services to
                            exclusively support your business and to determine
                            how best to achieve them.
                          </p>
                        </div>
                      </div>

                      {/* <div className='video-wrapper position-relative mb--40'>
                      <div className='thumbnail'>
                        <img
                          src='/assets/images/blog/bl-big-01.jpg'
                          alt='Blog Images'
                        />
                      </div>
                      <ModalVideo
                        channel='youtube'
                        isOpen={this.state.isOpen}
                        videoId='ZOoVOfieAF8'
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button
                        className='video-popup position-top-center'
                        onClick={this.openModal}
                      >
                        <span className='play-icon'></span>
                      </button>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Blog Details */}
          <div className='service-area ptb--75  bg_image bg_image--3'>
            <div className='container'>
              <ServiceTwo />
            </div>
          </div>

          {/* <div className='service-area ptb--75  bg_image bg_image--1'>
          <div className='container'>
            <ImageList />
          </div>
        </div> */}
          {/* Start Service Area  */}
          {/* <div className='service-area pt--120 pb--110  bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title text-center service-style--3 mb--30'>
                  <h2 className='title'>All Works</h2>
                </div>
              </div>
            </div>
          </div>
          <div className='wrapper portfolio-sacousel-inner mb--55'>
            <div className='portfolio-slick-activation mt--70 mt_sm--40'>
              <Slider {...portfolioSlick2}>
                {PortfolioList2.map((value, index) => (
                  <div className='portfolio' key={index}>
                    <div className='thumbnail-inner'>
                      <div className={`thumbnail ${value.image}`}></div>
                      <div className={`bg-blr-image ${value.image}`}></div>
                    </div>
                    <div className='content'>
                      <div className='inner'>
                        <p>{value.category}</p>
                        <h4>
                          <a href='/portfolio-details'>{value.title}</a>
                        </h4>
                        <div className='portfolio-button'>
                          <a className='rn-btn' href='/portfolio-details'>
                            Case Study
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div> */}
          {/* End Service Area  */}

          {/* Start CounterUp Area */}

          {/* End CounterUp Area */}
          {/* Start Testimonial Area */}
          <div className='rn-testimonial-area ptb--120 bg_color--1'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='section-title text-center service-style--3 mb--30'>
                    <h2 className='title'>OUR CLIENTS</h2>
                    {/* <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p> */}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <Brand />
                </div>
              </div>
            </div>
          </div>

          {/* End Testimonial Area */}

          {/* Start Blog Area */}
          <div className='rn-blog-area pt--120 pb--80 bg_color--5'>
            <div className='container'>
              <Testimonial />
            </div>
          </div>
          {/* End Blog Area */}
          <div className='portfolio-area ptb--120 bg_color--1'>
            <div className='portfolio-sacousel-inner mb--55 mb_sm--30'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='section-title text-center service-style--3 mb--30'>
                    <h2 className='title'>OUR MEMORIES</h2>
                    {/* <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration.
                  </p> */}
                  </div>
                </div>
              </div>
              <ImageList />
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              padding: "30px",
              fontFamily: "nasa",
              fontSize: "42px",
            }}
          >
            <div
              style={{
                padding: "20px",
                color: "#e88c11",
                textTransform: "capitalize",
                fontSize: "141px",
              }}
            >
              PYXIDIA
            </div>
            <div style={{ padding: "20px" }}>Techlab</div>
          </div> */}
          {/* Start call To Action  */}
          <CallAction />
          {/* End call To Action  */}

          {/* Start Footer Style  */}
          <FooterTwo />
          {/* End Footer Style  */}
          {/* Start Back To Top */}
          <div className='backto-top'>
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
        </div>

        {/* End Back To Top */}
      </Fragment>
    )
  }
}

export default DigitalAgency
