import React, { Component } from "react"
import PageHelmet from "../component/common/Helmet"
import Breadcrumb from "../elements/common/Breadcrumb"
import BlogList from "../elements/blog/BlogList"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"
import Header from "../component/header/Header"
import FooterTwo from "../component/footer/FooterTwo"

class Blog extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle='BLOG' />

        <Header
          headertransparent='header--transparent'
          colorblack='color--black'
          logoname='logo.png'
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"BLOGS"} />
        {/* End Breadcrump Area */}

        {/* Start Blog Area */}
        <div className='rn-blog-area ptb--120 bg_color--3'>
          <div className='container'>
            <BlogList />
          </div>
        </div>
        {/* End Blog Area */}

        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    )
  }
}
export default Blog
