import React, { Component } from "react"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"
import PageHelmet from "../../component/common/Helmet"
import Header from "../../component/header/Header"
import Footer from "../../component/footer/Footer"

class BlogDetails3 extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle='Blog Details' />
        <Header
          headertransparent='header--transparent'
          colorblack='color--black'
          logoname='logo.png'
        />

        {/* Start Breadcrump Area */}
        <div
          className='rn-page-title-area pt--120 pb--190 bg_image bg_image--7'
          data-black-overlay='7'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='blog-single-page-title text-center pt--100'>
                  <h2 className='title theme-gradient'>
                    How to attract Passive Candidates from the Job Market?
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className='rn-blog-details pt--110 pb--70 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner-wrapper'>
                  <div className='inner'>
                    <p>
                      Passive candidates are individuals who are currently
                      employed but are open to new job opportunities. These
                      candidates are not actively looking for a job but may be
                      interested in a new role if the right opportunity comes
                      along. Attracting passive candidates can be a challenge
                      for recruiters, but there are several strategies that can
                      be used to appeal to these candidates and encourage them
                      to consider a new job opportunity.
                    </p>
                    <p>
                      Use social media to your advantage: Passive candidates are
                      often active on social media platforms such as LinkedIn,
                      Facebook, and Twitter. By using these platforms to
                      showcase your company culture, values, and job
                      opportunities, you can pique the interest of passive
                      candidates who may be browsing their feeds.
                    </p>
                    <p>
                      Build a strong employer brand: Candidates, whether active
                      or passive, want to work for a company with a strong
                      employer brand. This means creating a positive and
                      engaging company culture that is attractive to potential
                      employees. By showcasing your company culture and values,
                      you can attract the attention of passive candidates who
                      may be interested in joining your team.
                    </p>
                    <p>
                      Use employee referrals: Referrals from current employees
                      are one of the most effective ways to attract passive
                      candidates. Your employees know the company culture, work
                      environment, and job requirements, making them excellent
                      ambassadors for your organization. Encourage your
                      employees to refer their connections, and offer incentives
                      for successful referrals.
                    </p>
                    <div className='thumbnail'>
                      <img
                        src='/assets/images/blog/blogs-003.jpeg'
                        alt='Blog Images'
                      />
                    </div>
                    <p className='mt--40'>
                      Attend industry events: Industry events and conferences
                      are excellent opportunities to connect with passive
                      candidates in your field. By attending these events and
                      networking with attendees, you can build relationships
                      with potential candidates who may be interested in a new
                      job opportunity.
                    </p>
                    <p>
                      Create compelling job postings: Passive candidates may not
                      be actively looking for a job, but they may be open to a
                      new opportunity if the job posting is compelling enough.
                      When creating job postings, focus on the key selling
                      points of the role and your company, highlighting the
                      benefits of joining your team.
                    </p>
                    <p>
                      Offer competitive compensation packages: Passive
                      candidates are not desperate for a new job, so you need to
                      offer competitive compensation packages to attract their
                      attention. This means offering a salary and benefits
                      package that is in line with industry standards and that
                      rewards the skills and experience of the candidate.
                    </p>
                    <p>
                      In conclusion, attracting passive candidates requires a
                      proactive and creative approach. By leveraging social
                      media, building a strong employer brand, using employee
                      referrals, attending industry events, creating compelling
                      job postings, and offering competitive compensation
                      packages, you can attract the attention of top talent and
                      encourage them to consider a new job opportunity
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}
        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }
}
export default BlogDetails3
