import React, { Component } from "react"
import { Helmet } from "react-helmet"

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.props.pageTitle} || PYXIDIA TECHLAB </title>
          <meta
            name='description'
            content='PYXIDIA TECHLAB – Expert IT consultants, efficient payroll management, all under one roof. Your one-stop-shop for all things tech..'
          />
        </Helmet>
      </React.Fragment>
    )
  }
}

export default PageHelmet
