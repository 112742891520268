const BlogContent = [
  {
    images: "01",
    title: "X-Ray Search for LinkedIn",
    category: "HR management",
    route: "/blogs/x-ray-search-linkedIn",
  },
  {
    images: "02",
    title: "Boolean Search",
    category: "HR Management",
    route: "/blogs/boolean-search",
  },

  {
    images: "03",
    title: "How to attract Passive Candidates from the Job Market?",
    category: "HR Management",
    route: "/blogs/passive-candidate",
  },
  {
    images: "04",
    title: " IT Market Flourishing in Raipur, Chhattisgarh",
    category: "HR Management",
    route: "/blogs/it-market",
  },
  {
    images: "05",
    title: "How do Build a strong professional network in the job market? ",
    category: "Network",
    route: "/blogs/strong-network",
  },
  {
    images: "06",
    title: "Crack a successful Job Interview in IT industry",
    category: "IT industry",
    route: "/blogs/interview-it-industry",
  },
]

export default BlogContent
