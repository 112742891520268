import React, { Component } from "react"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"
import PageHelmet from "../../component/common/Helmet"
import Footer from "../../component/footer/Footer"
import Header from "../../component/header/Header"

class ComplianceServices extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle='Compliance Services' />
        {/* End Pagehelmet  */}

        <Header
          headertransparent='header--transparent'
          colorblack='color--black'
          logoname='logo.png'
        />

        {/* Start Breadcrump Area */}
        <div
          className='rn-page-title-area pt--120 pb--190 bg_image bg_image--5'
          data-black-overlay='5'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='rn-page-title text-center pt--100'>
                  <h2 className='title theme-gradient'>Compliance Services</h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className='rn-service-details ptb--120 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='service-details-inner'>
                  <div className='inner'>
                    {/* Start Single Area */}
                    <div className='row sercice-details-content align-items-center'>
                      <div className='col-lg-6 col-12 order-2 order-lg-1'>
                        <div className='details mt_md--30 mt_sm--30'>
                          <p>
                            Pyxidia understands the challenges that businesses
                            face in maintaining compliance with government
                            regulations. That's why we offer Compliance Services
                            to help companies conform to the standards mandated
                            by the government. Our team of experts stays
                            up-to-date with the latest laws and regulations to
                            ensure that your company stays compliant and avoids
                            any legal consequences.
                          </p>
                          <h5 className='title mt-2'>
                            {" "}
                            Our services are designed to simplify the compliance
                            process for businesses of all sizes. We offer a wide
                            range of services, including:
                          </h5>
                          <ul className='liststyle'>
                            <li>
                              Compliance management: Our experts will work with
                              you to identify the regulatory requirements that
                              apply to your business, and help you develop and
                              implement a compliance management plan to meet
                              those requirements.
                            </li>
                            <li>
                              Compliance assessment: We will review your
                              existing policies, procedures, and systems to
                              identify any areas where you may be out of
                              compliance, and recommend corrective actions to
                              bring you into compliance.
                            </li>
                            <li>
                              Ongoing compliance support: Our team will provide
                              ongoing support to help you maintain compliance
                              with government regulations, including regular
                              reviews of your compliance management plan and
                              updates to ensure that you are staying up-to-date
                              with the latest regulatory requirements.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-lg-6 col-12 order-1 order-lg-2'>
                        <div className='thumb position-relative'>
                          <img
                            className='w-100'
                            src='/assets/images/service/service-01.png'
                            alt='Service Images'
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }
}
export default ComplianceServices
