import React, { useState, useEffect } from "react"

const Brand = ({ branstyle }) => {
  const brandImages = [
    "/assets/images/brand/brand-01.png",
    "/assets/images/brand/brand-02.png",
    "/assets/images/brand/brand-03.png",
    "/assets/images/brand/brand-04.png",
    "/assets/images/brand/brand-05.png",
    "/assets/images/brand/brand-06.png",
  ]

  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((currentImage + 1) % brandImages.length)
    }, 5000)
    return () => clearInterval(interval)
  }, [currentImage, brandImages.length])

  return (
    <ul className={`brand-list ${branstyle}`}>
      {brandImages.map((image, index) => (
        <li
          key={index}
          style={{
            backgroundColor: index === currentImage ? "#e7850c" : "transparent",
            borderRadius: "6px",
            transform: index === currentImage ? "scale(1.1)" : "scale(1)",
            transition: "transform 0.5s ease",
          }}
        >
          <img
            src={image}
            alt='Logo Images'
            style={{
              opacity: "1",
              filter:
                index === currentImage ? "brightness(0) invert(1)" : "none",
            }}
          />
        </li>
        //   <li key={index}>
        //   <img
        //     src={image}
        //     alt='Logo Images'
        //     style={{
        //       opacity: "1",
        //       transform: index === currentImage ? "scale(1.3)" : "scale(1)",
        //       transition: "transform 0.5s ease",
        //     }}
        //   />
        // </li>
      ))}
    </ul>
  )
}

export default Brand
