import React, { Component } from "react"
import PageHelmet from "../component/common/Helmet"
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi"
import ContactTwo from "../elements/contact/ContactTwo"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"
import Header from "../component/header/Header"
import FooterTwo from "../component/footer/FooterTwo"
import Iframe from "react-iframe"

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  }

  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle='Contact Us' />

        <Header
          headertransparent='header--transparent'
          colorblack='color--black'
          logoname='logo.png'
        />

        {/* Start Breadcrump Area */}
        <div
          className='rn-page-title-area pt--120 pb--190 bg_image bg_image--17'
          data-black-overlay='6'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='rn-page-title text-center pt--100'>
                  <h2 className='title theme-gradient'>GIVE US A SHOUT OUT</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className='rn-contact-top-area ptb--120 bg_color--5'>
          <div className='container'>
            <div className='row'>
              {/* Start Single Address  */}
              <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                <div className='rn-address' style={{ minHeight: "210px" }}>
                  <div className='icon'>
                    <FiHeadphones />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>Let’s Talk</h4>
                    <p>
                      <a href='tel:+91 0771- 65094'>+91 0771- 65094</a>
                    </p>
                    <p style={{ height: "90px" }}>
                      <a href='tel:+91 93997 54876'>+91 93997 54876</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50'>
                <div className='rn-address' style={{ minHeight: "210px" }}>
                  <div className='icon'>
                    <FiMail />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>Write To Us</h4>
                    <p>
                      <a href='mailto:business@pyxidiatech.com'>
                        business@pyxidiatech.com
                      </a>
                    </p>
                    <p style={{ height: "90px" }}>
                      {" "}
                      <a href='mailto:career@pyxidiatech.com'>
                        career@pyxidiatech.com
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50'>
                <div className='rn-address' style={{ minHeight: "210px" }}>
                  <div className='icon'>
                    <FiMapPin />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>Meet Us</h4>
                    <p style={{ height: "120px" }}>
                      Second Floor,Tower, B, ZUDIO, Katoratalab Nirmal, 32,
                      Shailendra Nagar, Raipur, Chhattisgarh 492001
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Contact Map  */}
        <div className='container pt--70'>
          <div style={{ background: "white", overflow: "hidden" }}>
            {/* <iframe width="1260" height="620" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=1260&amp;height=620&amp;hl=en&amp;q=Second%20Floor,Tower,%20B,%20ZUDIO,%20Katoratalab%20Nirmal,%2032,%20Shailendra%20Nagar,%20Raipur,%20Chhattisgarh%20492001+(PYXIDIA)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure acres/hectares on map</a></iframe> */}
            <Iframe
              src='https://maps.google.com/maps?width=1260&amp;height=620&amp;hl=en&amp;q=Second%20Floor,Tower,%20B,%20ZUDIO,%20Katoratalab%20Nirmal,%2032,%20Shailendra%20Nagar,%20Raipur,%20Chhattisgarh%20492001+(PYXIDIA)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
              width='1260'
              height='620'
              style='border:0;'
              allowfullscreen=''
              loading='lazy'
              referrerpolicy='no-referrer-when-downgrade'
            ></Iframe>
          </div>
        </div>
        {/* End Contact Map  */}
        {/* Start Contact Page Area  */}
        <div className='rn-contact-page ptb--120 bg_color--1'>
          <ContactTwo />
          {/* <ContactOne /> */}
          {/* <ContactThree /> */}
        </div>
        {/* End Contact Page Area  */}

        {/* Start Brand Area */}
        {/* <div className='rn-brand-area brand-separation bg_color--5 ptb--120'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <BrandTwo />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    )
  }
}
export default Contact
