import React, { Component } from "react"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp } from "react-icons/fi"
import PageHelmet from "../../component/common/Helmet"
import Header from "../../component/header/Header"
import Footer from "../../component/footer/Footer"

class BlogDetails1 extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle='Blog Details' />
        <Header
          headertransparent='header--transparent'
          colorblack='color--black'
          logoname='logo.png'
        />

        {/* Start Breadcrump Area */}
        <div
          className='rn-page-title-area pt--120 pb--190 bg_image bg_image--7'
          data-black-overlay='7'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='blog-single-page-title text-center pt--100'>
                  <h2 className='title theme-gradient'>
                    X-Ray Search for LinkedIn
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className='rn-blog-details pt--110 pb--70 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner-wrapper'>
                  <div className='inner'>
                    <p>
                      In today's digital age, the recruitment process has
                      shifted from traditional methods to a more tech-savvy
                      approach. One such approach is the use of an X-ray search
                      for recruitment on LinkedIn. X-ray search is a powerful
                      search tool that allows recruiters to find the right
                      candidates quickly and efficiently.
                    </p>
                    <p>
                      LinkedIn is one of the world's largest professional
                      networking platforms, with over 700 million users in more
                      than 200 countries. It is a goldmine for recruiters
                      seeking candidates with specific skills and experience.
                      Xray search is a way to enhance the power of LinkedIn
                      search by providing additional filters and features that
                      allow recruiters to narrow down their search even further.
                    </p>
                    <p>
                      Xray search allows recruiters to search for candidates
                      based on various criteria such as job title, company,
                      location, industry, education, and experience. The tool
                      also enables recruiters to filter their search based on
                      specific keywords, which can help them find candidates
                      with specific skills or expertise.
                    </p>
                    <div className='thumbnail'>
                      <img
                        src='/assets/images/blog/blogs-001.jpeg'
                        alt='Blog Images'
                      />
                    </div>
                    <p className='mt--40'>
                      The use of Xray search can save recruiters a significant
                      amount of time and effort in the recruitment process.
                      Instead of sifting through numerous LinkedIn profiles
                      manually, recruiters can use Xray search to quickly find
                      the right candidates that match their requirements. This
                      saves them time and effort, allowing them to focus on
                      other essential aspects of the recruitment process.
                    </p>
                    <p>
                      Moreover, an X-ray search can help recruiters identify
                      passive candidates who may not be actively looking for a
                      job but could be interested in new opportunities. These
                      candidates may not have updated their LinkedIn profiles,
                      making them difficult to find through conventional search
                      methods. However, an X-ray search can help recruiters
                      identify these candidates by searching for keywords
                      related to their skills or industry.
                    </p>
                    <p>
                      In conclusion, an X-ray search is an excellent tool for
                      recruiters to find suitable candidates quickly and
                      efficiently. By leveraging the power of LinkedIn and Xray
                      search, recruiters can streamline their recruitment
                      process and find the best candidates for their
                      organization.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}
        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }
}
export default BlogDetails1
